import { gql, useMutation } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import {
	FileAccessLevelChangeRequest,
	Mutation,
	MutationFileAccessLevelChangeArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import {
	FILE_INFO_FIELDS_NO_FOLDER,
	FILE_INSTANCE_INFO_FIELDS_NO_FOLDER,
} from '../../utils/fragments.graphql';

const CHANGE_ACCESS_LEVEL = gql`
	${FILE_INFO_FIELDS_NO_FOLDER}
	${FILE_INSTANCE_INFO_FIELDS_NO_FOLDER}
	mutation ChangeAccessLevel($request: FileAccessLevelChangeRequest!) {
		fileAccessLevelChange(request: $request) {
			...FileInfoFieldsNoFolder
			instances {
				...FileInstanceInfoFieldsNoFolder
			}
		}
	}
`;

export const useAccessLevelChange = () => {
	const toast = useToast();

	const [_changeAccessLevel] = useMutation<
		Pick<Mutation, 'fileAccessLevelChange'>,
		MutationFileAccessLevelChangeArgs
	>(CHANGE_ACCESS_LEVEL, { onError: (e) => console.log(JSON.stringify(e)) });

	const changeAccessLevel = async (request: FileAccessLevelChangeRequest) => {
		return await _changeAccessLevel({ variables: { request } })
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Privacy updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return changeAccessLevel;
};
