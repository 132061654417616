import { BugReport, Warning } from '@mui/icons-material';
import { Avatar, Box, ButtonBase, Stack, Typography } from '@mui/material';
import { MessageAttachmentUploadSpinner } from 'components/ui/uploads/upload-status-display';
import { filesize } from 'filesize';
import { FileInstanceInformation, VirusStatus } from 'middleware-types';
import {
	ExtensionIcon,
	UploadState,
	downloadFileObject,
	useDownloadFileInstance,
} from 'utils/fileUtils';

interface MessageFileChipProps {
	fileData: File | FileInstanceInformation;
	uploadState?: UploadState;
	uploadProgress?: number;
	submitting?: boolean;
	onRemove?: () => void;
}

export const MessageFileChip = ({
	fileData,
	uploadState = UploadState.Success,
	uploadProgress,
	submitting,
	onRemove,
}: MessageFileChipProps) => {
	const downloadFileInstance = useDownloadFileInstance();
	const downloadFile = (inline: boolean) => {
		if (uploadState !== UploadState.Success) return;

		if (fileData instanceof File) {
			downloadFileObject(fileData, inline);
		} else {
			downloadFileInstance(fileData, inline);
		}
	};

	let name = '';
	let size = 0;
	let virusStatus = VirusStatus.Clean;
	if (fileData instanceof File) {
		name = fileData.name;
		size = fileData.size;
	} else {
		name = fileData.fileName;
		size = fileData.fileSize;
		virusStatus = fileData.virusStatus;
	}

	return (
		<ButtonBase
			component="div"
			disabled={uploadState !== UploadState.Success || submitting}
			onClick={() => downloadFile(true)}
			sx={{
				border: '1px solid',
				borderColor: 'neutral.200',
				borderRadius: 1,
				flexShrink: 0,
			}}>
			<Stack direction="row" alignItems="center" spacing={1} p={1}>
				<Avatar>
					<ExtensionIcon filename={name} />
				</Avatar>
				<Box textAlign="left">
					<Typography variant="h6" sx={{ color: 'neutral.700' }}>
						{name}
					</Typography>
					{virusStatus === VirusStatus.Infected ? (
						<Stack direction="row" alignItems="center">
							<BugReport fontSize="small" color="error" sx={{ mb: 0.5 }} />
							<Typography variant="body2" color="error.dark">
								Infected
							</Typography>
						</Stack>
					) : virusStatus === VirusStatus.TooLargeToScan ? (
						<Stack direction="row" alignItems="center">
							<Typography variant="body2" sx={{ color: 'neutral.700' }}>
								{filesize(size)}
							</Typography>
							<Warning fontSize="small" color="warning" sx={{ mb: 0.5, ml: 1 }} />
							<Typography variant="body2" color="warning.dark">
								Too Large to Scan
							</Typography>
						</Stack>
					) : virusStatus === VirusStatus.ScanFailed ? (
						<Stack direction="row" alignItems="center">
							<Typography variant="body2" sx={{ color: 'neutral.700' }}>
								{filesize(size)}
							</Typography>
							<Warning fontSize="small" color="warning" sx={{ mb: 0.5, ml: 1 }} />
							<Typography variant="body2" color="warning.dark">
								Scan Failed
							</Typography>
						</Stack>
					) : (
						<Typography variant="body2" sx={{ color: 'neutral.700' }}>
							{filesize(size)}
						</Typography>
					)}
				</Box>
				<MessageAttachmentUploadSpinner
					size={30}
					state={uploadState ?? UploadState.Pending}
					progress={uploadProgress ?? 0}
					onAttachmentDownload={downloadFile}
					onRemove={onRemove}
				/>
			</Stack>
		</ButtonBase>
	);
};
